<script lang="ts">
    import { OverlayScrollbars } from 'overlayscrollbars';
    import { onMount } from 'svelte';
    import Launcher from './Launcher.svelte';
    import type { PageData } from './$types';
    import { HeadTitle } from '$lib/service';
    import { goto } from '$app/navigation';

    export let data: PageData;

    const { transformUrl } = data.master;

    let contentsRef;

    onMount(async () => {
        OverlayScrollbars(contentsRef, {
            scrollbars: {
                autoHide: 'scroll',
            },
        });

        // 첫페이지 SSG 생성중에 /index.html 을 생성해야하는데 이걸 layout.ts 에서 팅겨버리면 index.html 이 생성되지 않아 문제가 생길 수 있으니 유의
        if (!data.session && !data.themeConfig?.notVisitSignin) {
            goto(transformUrl('/sign-in'), {
                replaceState: true,
            });
        }
    });
</script>

<HeadTitle title={data.title} />
<div class="grid h-auto min-h-full">
    <div bind:this={contentsRef} class="h-full min-w-[50rem] flex-1">
        {#if data.session}
            <Launcher />
        {/if}
    </div>
</div>
